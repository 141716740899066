import { inject, Injectable, isDevMode } from '@angular/core';
import { WA_WINDOW } from '@ng-web-apis/common';
import { MetaEventParams, MetaPixelStandardEvents } from '../domain/fb-events.model';
import { MetaPixelContext, MetaPixelContextService } from '../util-meta-pixel-context/meta-pixel-context.service';

declare global {
  interface Window {
    fbq?: (...args: unknown[]) => void;
  }
}

type MetaPixelTrackType = 'track' | 'trackCustom';

@Injectable({
  providedIn: 'root',
})
export class MetaPixelService {
  private readonly window = inject(WA_WINDOW);
  private readonly context = inject(MetaPixelContextService);

  /**
   * Log the events to the console, so you can debug the events. If this is true, a test will fail
   */
  private readonly is_debug = false;

  public track<E extends MetaPixelStandardEvents>(event: E, data?: MetaEventParams<E>) {
    this.trackEvent('track', event, data);
  }

  public trackCustom(event: string, data?: Record<string, unknown>) {
    this.trackEvent('trackCustom', event, data);
  }

  private trackEvent(type: MetaPixelTrackType, event: string, data?: Record<string, unknown>) {
    const params = this.mergeParams(data ?? {});

    if (this.is_debug && isDevMode()) {
      this.logEventToConsole(type, event, params);
      return;
    }

    if (this.window?.fbq) {
      this.window.fbq(type, event, params);
    }
  }

  private mergeParams<T extends Record<string, unknown>>(params: T): T & Partial<MetaPixelContext> {
    return {
      ...params,
      ...this.context.getContext(),
    };
  }

  private logEventToConsole(type: MetaPixelTrackType, event: string, data?: Record<string, unknown>): void {
    console.group(`[MetaPixel] ${type} event`);
    console.log(`Event: "${event}"`);
    console.log(`Params: `, JSON.stringify(data ?? {}, null, 2));
    console.groupEnd();
  }
}
