import { EnvironmentInjector, inject, Injectable, runInInjectionContext, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CurrentUserService } from '@rma/accounts/user';
import { map, Observable } from 'rxjs';

export interface MetaPixelContext {
  external_id: string;
}

@Injectable({ providedIn: 'root' })
export class MetaPixelContextService {
  private readonly userService = inject(CurrentUserService);
  private readonly injector = inject(EnvironmentInjector);
  private context?: Signal<Partial<MetaPixelContext>>;

  public getContext(): Partial<MetaPixelContext> {
    if (!this.context) {
      this.context = runInInjectionContext(this.injector, () => this.loadMetaContext());
    }

    return this.context();
  }

  private loadMetaContext(): Signal<Partial<MetaPixelContext>> {
    const userData$: Observable<Partial<MetaPixelContext>> = this.userService.currentUserContext$.pipe(
      map((user) => {
        const context: Partial<MetaPixelContext> = {};
        if (user?.userId) {
          context.external_id = user.userId;
        }
        return context;
      }),
    );
    return toSignal(userData$, { initialValue: {} });
  }
}
