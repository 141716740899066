import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { META_PIXEL_STANDARD_EVENTS, MetaPixelStandardEvents, MetaStandardEventParams } from '../domain/fb-events.model';
import { MetaPixelService } from '../util-meta-pixel/meta-pixel.service';

interface MetaPixelStandardRouteEvent {
  type?: 'standard';
  event: MetaPixelStandardEvents;
  params?: MetaStandardEventParams[MetaPixelStandardEvents];
}

interface MetaPixelCustomRouteEvent {
  type: 'custom';
  event: string;
  params?: Record<string, unknown>;
}

export type MetaPixelRouteEvent = MetaPixelStandardRouteEvent | MetaPixelCustomRouteEvent;

@Injectable({ providedIn: 'root' })
export class MetaPixelRouteTrackingService {
  private readonly router = inject(Router);
  private readonly pixel = inject(MetaPixelService);
  private readonly destroyRef = inject(DestroyRef);

  public init(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((e) => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.pixel.track('PageView');

        const route = this.getDeepestChild(this.router.routerState.snapshot.root);
        const rawRouteData = route.data?.['metaPixelEvents'];

        if (!rawRouteData) {
          return;
        }

        const events = this.normalizeEvents(rawRouteData);

        events?.forEach((e) => this.trackEvent(e));
      });
  }

  private trackEvent(e: MetaPixelRouteEvent) {
    if (this.isCustomEvent(e)) {
      this.pixel.trackCustom(e.event, e.params);
      return;
    }

    if (!this.isKnownStandardEvent(e.event)) {
      console.error(`[MetaPixel] Unknown standard event: "${e.event}"`);
      return;
    }

    this.pixel.track(e.event, e.params);
  }

  private isKnownStandardEvent(event: string): event is MetaPixelStandardEvents {
    return META_PIXEL_STANDARD_EVENTS.includes(event as MetaPixelStandardEvents);
  }

  private isCustomEvent(e: MetaPixelRouteEvent): e is MetaPixelCustomRouteEvent {
    return e.type === 'custom';
  }

  private getDeepestChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private normalizeEvents(raw: MetaPixelRouteEvent | MetaPixelRouteEvent[]): MetaPixelRouteEvent[] | undefined {
    if (!raw) {
      return;
    }
    return Array.isArray(raw) ? raw : [raw];
  }
}
