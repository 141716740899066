import { EnvironmentProviders, inject, isDevMode, makeEnvironmentProviders, provideAppInitializer } from '@angular/core';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { MetaPixelRouteTrackingService } from './meta-pixel-route-tracking.service';

function initialiseMetaPixelRouteTrackingProvider(
  metaPixelRouterTracking: MetaPixelRouteTrackingService,
  environment: Environment,
  platformSerivce: PlatformService,
) {
  return () => {
    if ((platformSerivce.isPlatformBrowser && environment.tracking.facebookPixelId) || isDevMode()) {
      metaPixelRouterTracking.init();
    }
  };
}

export function provideRmaMetaPixelRouteTracking(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideAppInitializer(() => {
      const initializerFn = initialiseMetaPixelRouteTrackingProvider(
        inject(MetaPixelRouteTrackingService),
        inject(Environment),
        inject(PlatformService),
      );
      return initializerFn();
    }),
  ]);
}
