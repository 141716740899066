export const META_PIXEL_STANDARD_EVENTS = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
  'PageView',
] as const;

export type MetaPixelStandardEvents = (typeof META_PIXEL_STANDARD_EVENTS)[number];
export type MetaEventParams<E extends MetaPixelStandardEvents & keyof MetaStandardEventParams> = MetaStandardEventParams[E];
export interface MetaStandardEventParams {
  AddPaymentInfo: {
    value?: number;
    currency?: string;
  };
  AddToCart: {
    content_ids?: string[];
    content_name?: string;
    content_type?: string;
    currency?: string;
    value?: number;
  };
  AddToWishlist: {
    content_ids?: string[];
    content_name?: string;
    content_type?: string;
    currency?: string;
    value?: number;
  };
  CompleteRegistration: {
    status?: string;
    value?: number;
    currency?: string;
  };
  Contact: {
    content_name?: string;
  };
  CustomizeProduct: Record<string, unknown>;
  Donate: {
    currency?: string;
    value?: number;
  };
  FindLocation: Record<string, unknown>;
  InitiateCheckout: {
    value?: number;
    currency?: string;
    content_ids?: string[];
  };
  Lead: {
    content_name?: string;
    value?: number;
    currency?: string;
  };
  PageView: undefined;
  Purchase: {
    content_ids?: string[];
    content_name?: string;
    currency: string;
    value: number;
  };
  Schedule: Record<string, unknown>;
  Search: {
    search_string?: string;
  };
  StartTrial: {
    value?: number;
    currency?: string;
  };
  SubmitApplication: Record<string, unknown>;
  Subscribe: {
    value?: number;
    currency?: string;
  };
  ViewContent: {
    content_ids?: string[];
    content_name?: string;
    content_type?: string;
    value?: number;
    currency?: string;
  };
}
